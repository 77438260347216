import React, { useEffect, useState, useCallback } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { graphql, useStaticQuery } from "gatsby";
import {
  listCheckouts,
  getProductsByCheckout,
  getOrderProductsByOrderId,
} from "src/graphql/myQueries";
import { listSelections, listOrders } from "src/graphql/queries";
import uniq from "lodash/uniq";
import isEmpty from "lodash/isEmpty";

const defaultState = {
  skuData: [{ data: {} }, { data: {} }],
  looksData: [],
  brandsData: [],
  checkout: [],
  products: [],
  orders: [],
  unconfirmedOrders: [],
};
const Context = React.createContext(defaultState);
function ContextProvider({ children }) {
  const data = useStaticQuery(
    graphql`
      query {
        allAirtableSku {
          nodes {
            data {
              Available_sizes
              Description
              Name
              BrandName
              RRP
              SKU
              SKU_Photos {
                localFiles {
                  id
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  url
                }
              }
              Title
              Wholesale_Price
              Colors

              Composition
            }
          }
        }

        allAirtableLooks {
          nodes {
            id
            data {
              Collection
              Brand
              Name
              BrandName
              Name__from_SKU_
              Photo {
                localFiles {
                  id
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  url
                }
              }
              SKU {
                data {
                  SKU
                  Title
                  Name
                  Category
                }
              }
            }
          }
        }

        allAirtableBrands {
          nodes {
            data {
              Description
              Name
              Title
              Photos {
                localFiles {
                  id
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  url
                }
              }

              Logo {
                localFiles {
                  id
                  childImageSharp {
                    fluid(maxHeight: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }

              Season
              Category
              Status
              Slug
              MOQ
              Delivery_Dates
              Delivery_Terms
              Payment_Terms
            }
          }
        }
      }
    `
  );

  const looksData = data.allAirtableLooks.nodes;
  const skuData = data.allAirtableSku.nodes;
  const brandsData = data.allAirtableBrands.nodes;

  const [checkout, setCheckout] = useState({});
  const [products, setProducts] = useState([]);
  const [selections, setSelections] = useState([]);

  const [orders, setOrders] = useState([]);
  const [unconfirmedOrders, setUnconfirmedOrders] = useState([]);
  const [checkoutBrands, setCheckoutBrands] = useState(new Set());

  const defaultBrand = brandsData[0].data.Name;

  const [selectedBrand, setSelectedBrand] = useState(defaultBrand);

  const fetchCheckouts = useCallback(async () => {
    try {
      const ItemsData = await API.graphql(graphqlOperation(listCheckouts));

      const checkoutsList = ItemsData.data.listCheckouts.items;
      if (checkoutsList[0]) {
        setCheckout(checkoutsList[0]);
      }
      console.log("checkout", checkoutsList[0]);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchProducts = useCallback(async (checkoutId) => {
    try {
      const products = await API.graphql(
        graphqlOperation(getProductsByCheckout, {
          checkoutId: checkoutId,
        })
      );
      const productsItems = products.data.getProductsByCheckout.items;

      setProducts(productsItems);

      console.log(`products`, productsItems);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchSelections = useCallback(async () => {
    try {
      const ItemsData = await API.graphql(graphqlOperation(listSelections));

      const selectionsList = ItemsData.data.listSelections.items;

      setSelections(selectionsList);

      console.log("selections", selectionsList);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchOrders = useCallback(async () => {
    try {
      const ItemsData = await API.graphql(graphqlOperation(listOrders));

      const ordersList = ItemsData.data.listOrders.items;

      setOrders(ordersList);
      console.log("Orders", ordersList);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const addUnconfirmedOrders = useCallback(
    (newOptions) => {
      const newOrders = [];
      [...newOptions].forEach((brand) => {
        const newOrder = products.filter((product) => product.brand === brand);

        newOrders.push(newOrder);
      });
      setUnconfirmedOrders(uniq(newOrders));

      console.log("unconfirmedOrders", uniq(newOrders));
    },
    [products]
  );

  const addCheckoutBrands = useCallback(() => {
    let newOptions = new Set();

    products.forEach((product) => {
      newOptions.add(product.brand);
    });

    setCheckoutBrands(newOptions);
    console.log("checkoutBrands", newOptions);
    addUnconfirmedOrders(newOptions);
  }, [addUnconfirmedOrders, products]);

  const getProductsByOrderId = useCallback(async (id, setItems) => {
    try {
      const productsByOrderId = await API.graphql(
        graphqlOperation(getOrderProductsByOrderId, {
          orderId: id,
        })
      );

      const orderItems = await productsByOrderId.data.getOrderProductsByOrderId.items.map(
        ({ product }) => product
      );
      console.log(orderItems);
      setItems(orderItems);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchCheckouts();
    fetchSelections();
    fetchOrders();
  }, [fetchCheckouts, fetchSelections, fetchOrders]);

  useEffect(() => {
    !isEmpty(checkout) && fetchProducts(checkout.id);
  }, [fetchCheckouts, checkout, fetchProducts]);

  useEffect(() => {
    addCheckoutBrands();
  }, [products, addCheckoutBrands]);

  return (
    <Context.Provider
      value={{
        skuData,
        looksData,
        brandsData,

        checkout,
        setCheckout,
        fetchCheckouts,
        checkoutBrands,

        products,
        setProducts,
        fetchProducts,
        getProductsByOrderId,

        selections,
        setSelections,
        fetchSelections,

        selectedBrand,
        setSelectedBrand,

        orders,

        setOrders,
        fetchOrders,

        unconfirmedOrders,
        addCheckoutBrands,
        addUnconfirmedOrders,
      }}
    >
      {children}
    </Context.Provider>
  );
}
export { Context, ContextProvider };
