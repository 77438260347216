import React, { useState, useCallback } from "react";
import {
  AppBar,
  Container,
  Toolbar,
  Grid,
  Hidden,
  IconButton,
} from "@material-ui/core";
import styles from "./styles";
import { navigate } from "gatsby";
import DehazeIcon from "@material-ui/icons/Dehaze";
import CloseIcon from "@material-ui/icons/Close";

import { DrawerContent, Username, Logo } from "./elements";

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

const Header = () => {
  const classes = styles();

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const cart = (
    <IconButton
      aria-label="cart"
      color="primary"
      onClick={() => navigate("/checkout")}
    >
      <ShoppingCartIcon className={`${classes.icon} ${classes.active}`} />
    </IconButton>
  );

  const menuButton = (
    <IconButton
      onClick={toggleExpanded}
      color="primary"
      className={classes.active}
      aria-label="menu"
    >
      {isExpanded ? (
        <CloseIcon className={classes.icon} />
      ) : (
        <DehazeIcon className={classes.icon} />
      )}
    </IconButton>
  );
  return (
    <AppBar position="relative" elevation={0} className={classes.AppBar}>
      <Container>
        <Toolbar component="nav" disableGutters className={classes.Toolbar}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Logo />
            </Grid>

            <Hidden implementation="css" smDown>
              <Grid item xs={12} sm="auto" className={classes.usernameWrapper}>
                <Username />
              </Grid>
            </Hidden>

            <Grid item>{menuButton}</Grid>

            <Grid item>{cart}</Grid>
          </Grid>
        </Toolbar>
        {isExpanded && (
          <DrawerContent cart={cart} toggleExpanded={toggleExpanded} />
        )}
      </Container>
    </AppBar>
  );
};

export default Header;
