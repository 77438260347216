/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:88d62252-f103-4b3a-afd0-b041afb188bc",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_xiWWXDkXS",
    "aws_user_pools_web_client_id": "6l6vduj5se5d0vq1o7quvf65n6",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://koqbblylbvb4nfaefq3wyesmpy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
