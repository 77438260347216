import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { navigate } from "gatsby";
import styles from "../styles";

const MobileRow = ({ data }) => {
  const classes = styles();

  return (
    <Grid item>
      <Typography
        onClick={() => navigate(`/brand/${data.Slug}`)}
        className={`${classes.label} ${classes.active}`}
        variant="body2"
        component="span"
      >
        {data.Name}
      </Typography>

      <Typography component="span" className={classes.greyText} variant="body2">
        {data.Season} | {data.Category} | {data.Status}
      </Typography>
    </Grid>
  );
};
export default MobileRow;
