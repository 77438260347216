import React from "react";
import { Typography } from "@material-ui/core";
import styles from "../../styles";
import { navigate } from "gatsby";
export const Logo = () => {
  const classes = styles();
  return (
    <Typography
      onClick={() => navigate("/")}
      component="span"
      className={`${classes.title} ${classes.active}`}
    >
      DEAR PROGRESS SHOWROOM
    </Typography>
  );
};
export default Logo;
