import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { Helmet } from "react-helmet";
import React from "react";
import { theme } from "../Theme";

import { SnackbarProvider } from "notistack";
import { ContextProvider } from "src/components/Context";
import { Box, Typography } from "@material-ui/core";
import Typist from "react-typist";

export default function ProviderWrapper({ children }) {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <ThemeProvider theme={theme}>
        <CssBaseline>
          <ContextProvider>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              content={(key, message) => (
                <Box
                  width="12.5rem"
                  height="2rem"
                  borderRadius="5px"
                  id={key}
                  bgcolor="primary.main"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typist>
                    <Typography
                      align="center"
                      variant="caption"
                      color="textSecondary"
                    >
                      {message}
                    </Typography>
                  </Typist>
                </Box>
              )}
            >
              {children}
            </SnackbarProvider>
          </ContextProvider>
        </CssBaseline>
      </ThemeProvider>
    </>
  );
}
