export const listCheckouts = /* GraphQL */ `
  query ListCheckouts(
    $filter: ModelCheckoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckouts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        products {
          items {
            id
            name
            SKU
            color
            items
            price
            WHP
            selection
            brand
            composition
          }
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        brand
        status
        product {
          items {
            id
            name
            SKU
            color
            items
            price
            WHP
            selection
            brand
          }
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

export const getProductsByCheckout = /* GraphQL */ `
  query GetProductsByCheckout(
    $checkoutId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProductsByCheckout(
      checkoutId: $checkoutId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        SKU
        color
        items
        price
        composition
        WHP
        selection
        brand
        RRP
        checkoutId
        checkout {
          id
          createdAt
          updatedAt
          owner
        }
        description
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

export const getOrderProductsByOrderId = /* GraphQL */ `
  query GetOrderProductsByOrderId(
    $orderId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderProductsByOrderId(
      orderId: $orderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        product {
          id
          name
          SKU
          color
          items
          price
          composition
          WHP
          selection
          RRP
          brand

          description
        }
      }
    }
  }
`;
