// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-admin-jsx": () => import("./../../../src/pages/admin.jsx" /* webpackChunkName: "component---src-pages-admin-jsx" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-change-password-jsx": () => import("./../../../src/pages/change-password.jsx" /* webpackChunkName: "component---src-pages-change-password-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-forgot-password-jsx": () => import("./../../../src/pages/forgot-password.jsx" /* webpackChunkName: "component---src-pages-forgot-password-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-request-access-jsx": () => import("./../../../src/pages/request-access.jsx" /* webpackChunkName: "component---src-pages-request-access-jsx" */),
  "component---src-templates-brand-index-jsx": () => import("./../../../src/templates/Brand/index.jsx" /* webpackChunkName: "component---src-templates-brand-index-jsx" */),
  "component---src-templates-sku-js": () => import("./../../../src/templates/SKU.js" /* webpackChunkName: "component---src-templates-sku-js" */)
}

