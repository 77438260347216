import { makeStyles } from "@material-ui/core";
const drawerWidth = "100%";
const styles = makeStyles((theme) => ({
  AppBar: {
    height: "100%",

    background: theme.palette.background.default,
    color: theme.palette.primary.main,
  },

  Toolbar: {
    height: "100%",
    minHeight: "6.25rem",
  },
  title: {
    fontSize: "16px !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "30px !important",
    },
  },
  icon: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
    },
  },

  select: {
    display: "flex",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: "0",
  },
  drawerPaper: {
    width: drawerWidth,
    position: "relative",
    whiteSpace: "nowrap",
  },
  active: {
    cursor: "pointer",
  },
  usernameWrapper: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "space-between",
    },

    alignItems: "center",
  },
  username: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  menu: {
    marginTop: "1rem",
  },
}));
export default styles;
