import React, { useContext } from "react";
import { Context } from "../../../Context";
import MobileRow from "./MobileRow";
import styles from "./styles";
import {
  Typography,
  Hidden,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Grid,
} from "@material-ui/core";
import { navigate } from "gatsby";
import { Username } from "../../elements";

export const DrawerContent = ({ toggleExpanded }) => {
  const classes = styles();
  const { brandsData } = useContext(Context);
  const headings = ["Label", "Season", "Category", "Status"].map((title) => (
    <TableCell className={classes.headerCell} key={title}>
      <Typography variant="body2">{title}</Typography>
    </TableCell>
  ));
  console.log("brandsdata", brandsData);

  const rows = brandsData.map(({ data }) => (
    <TableRow key={data.Name}>
      <TableCell className={classes.tableCell}>
        <Typography
          onClick={() => {
            navigate(`/brand/${data.Slug}`);
            toggleExpanded();
          }}
          className={`${classes.label} ${classes.active}`}
          variant="body1"
        >
          {data.Name}
        </Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell} ${classes.greyText}`}>
        <Typography variant="body1">{data.Season}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell} ${classes.greyText}`}>
        <Typography variant="body1">{data.Category}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell} ${classes.greyText}`}>
        <Typography variant="body1">{data.Status}</Typography>
      </TableCell>
    </TableRow>
  ));
  const mobileRows = brandsData.map(({ data }) => (
    <MobileRow data={data} key={data.Name} />
  ));
  return (
    <>
      <Hidden smDown>
        <TableContainer className={classes.table}>
          <Table aria-label="caption table">
            <TableHead>
              <TableRow>{headings}</TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </Hidden>

      <Hidden mdUp>
        <>
          <Grid item>
            {" "}
            <Username />
          </Grid>
          {mobileRows}{" "}
        </>
      </Hidden>
    </>
  );
};
export default DrawerContent;
