/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      SKU
      color
      items
      price
      composition
      WHP
      RRP
      selection
      brand
      checkoutId
      checkout {
        id
        products {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      orderProduct {
        id
        orderId
        productId
        order {
          id
          status
          brand
          email
          totalQuantity
          totalPrice
          deliveryTerms
          createdAt
          updatedAt
          owner
        }
        product {
          id
          name
          SKU
          color
          items
          price
          composition
          WHP
          RRP
          selection
          brand
          checkoutId
          description
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        SKU
        color
        items
        price
        composition
        WHP
        RRP
        selection
        brand
        checkoutId
        checkout {
          id
          createdAt
          updatedAt
          owner
        }
        orderProduct {
          id
          orderId
          productId
          createdAt
          updatedAt
          owner
        }
        description
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSelection = /* GraphQL */ `
  query GetSelection($id: ID!) {
    getSelection(id: $id) {
      id
      name
      SKU
      brand
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSelections = /* GraphQL */ `
  query ListSelections(
    $filter: ModelSelectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSelections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        SKU
        brand
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCheckout = /* GraphQL */ `
  query GetCheckout($id: ID!) {
    getCheckout(id: $id) {
      id
      products {
        items {
          id
          name
          SKU
          color
          items
          price
          composition
          WHP
          RRP
          selection
          brand
          checkoutId
          description
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCheckouts = /* GraphQL */ `
  query ListCheckouts(
    $filter: ModelCheckoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckouts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        products {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrderProduct = /* GraphQL */ `
  query GetOrderProduct($id: ID!) {
    getOrderProduct(id: $id) {
      id
      orderId
      productId
      order {
        id
        status
        brand
        email
        product {
          nextToken
        }
        totalQuantity
        totalPrice
        deliveryTerms
        createdAt
        updatedAt
        owner
      }
      product {
        id
        name
        SKU
        color
        items
        price
        composition
        WHP
        RRP
        selection
        brand
        checkoutId
        checkout {
          id
          createdAt
          updatedAt
          owner
        }
        orderProduct {
          id
          orderId
          productId
          createdAt
          updatedAt
          owner
        }
        description
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOrderProducts = /* GraphQL */ `
  query ListOrderProducts(
    $filter: ModelOrderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderId
        productId
        order {
          id
          status
          brand
          email
          totalQuantity
          totalPrice
          deliveryTerms
          createdAt
          updatedAt
          owner
        }
        product {
          id
          name
          SKU
          color
          items
          price
          composition
          WHP
          RRP
          selection
          brand
          checkoutId
          description
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      status
      brand
      email
      product {
        items {
          id
          orderId
          productId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      totalQuantity
      totalPrice
      deliveryTerms
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        brand
        email
        product {
          nextToken
        }
        totalQuantity
        totalPrice
        deliveryTerms
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      name
      surname
      email
      website
      phone
      store
      department
      status
      createdAt
      updatedAt
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        surname
        email
        website
        phone
        store
        department
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductsByBrand = /* GraphQL */ `
  query GetProductsByBrand(
    $brand: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProductsByBrand(
      brand: $brand
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        SKU
        color
        items
        price
        composition
        WHP
        RRP
        selection
        brand
        checkoutId
        checkout {
          id
          createdAt
          updatedAt
          owner
        }
        orderProduct {
          id
          orderId
          productId
          createdAt
          updatedAt
          owner
        }
        description
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getProductsByCheckout = /* GraphQL */ `
  query GetProductsByCheckout(
    $checkoutId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProductsByCheckout(
      checkoutId: $checkoutId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        SKU
        color
        items
        price
        composition
        WHP
        RRP
        selection
        brand
        checkoutId
        checkout {
          id
          createdAt
          updatedAt
          owner
        }
        orderProduct {
          id
          orderId
          productId
          createdAt
          updatedAt
          owner
        }
        description
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrderProductsByOrderId = /* GraphQL */ `
  query GetOrderProductsByOrderId(
    $orderId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderProductsByOrderId(
      orderId: $orderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        productId
        order {
          id
          status
          brand
          email
          totalQuantity
          totalPrice
          deliveryTerms
          createdAt
          updatedAt
          owner
        }
        product {
          id
          name
          SKU
          color
          items
          price
          composition
          WHP
          RRP
          selection
          brand
          checkoutId
          description
          RRP
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrdersByOwner = /* GraphQL */ `
  query GetOrdersByOwner(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByOwner(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        brand
        email
        product {
          nextToken
        }
        totalQuantity
        totalPrice
        deliveryTerms
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRequestsByEmail = /* GraphQL */ `
  query GetRequestsByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRequestsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        surname
        email
        website
        phone
        store
        department
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
