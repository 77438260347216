import { makeStyles } from "@material-ui/core";
const styles = makeStyles((theme) => ({
  footer: {
    height: "100%",
    background: "#FFFFFF",
    flexShrink: 0,
  },
  content: {
    padding: "1.875rem 0",
  },

  navLink: {
    cursor: "pointer",
    fontWeight: "bold",
  },
}));
export default styles;
