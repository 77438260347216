import React from "react";
import Auth from "@aws-amplify/auth";
import { setUser } from "./src/utils/auth";
import Amplify from "aws-amplify";
import config from "./src/aws-exports";
import ProviderWrapper from "./src/components/ProviderWrapper";
import Analytics from "@aws-amplify/analytics";

export const wrapRootElement = ({ element }) => (
  <ProviderWrapper>{element}</ProviderWrapper>
);
export const onRouteUpdate = (state, page, pages) => {
  Amplify.configure(config);
  Auth.currentAuthenticatedUser()
    .then((user) => {
      const userInfo = {
        ...user.attributes,
        username: user.username,
      };
      setUser(userInfo);
    })
    .catch((err) => {
      console.log(err);
      window.localStorage.setItem("gatsbyUser", null);
    });
  const analyticsConfig = {
    AWSPinpoint: {
      // Amazon Pinpoint App Client ID
      appId: "95fc9ec8987d4570800590f6ec309b30",
      // Amazon service region
      region: "us-east-1",
      mandatorySignIn: false,
    },
  };

  Analytics.configure(analyticsConfig);
};
export const shouldUpdateScroll = () => {
  return false;
};
