import React, { useState } from "react";
import { Typography, MenuItem, Menu } from "@material-ui/core";
import styles from "../../styles";
import { navigate } from "gatsby";
import isEmpty from "lodash/isEmpty";
import { logout, getUser } from "src/utils/auth";
import { Auth } from "aws-amplify";

export const Username = () => {
  const classes = styles();
  const [anchorEl, setAnchorEl] = useState(null);

  const username = getUser().username;
  async function signOut() {
    try {
      await Auth.signOut();
      navigate("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  const handleClick = (event) => {
    isEmpty(username) ? navigate("/login") : setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Typography
        component="span"
        onClick={handleClick}
        className={`${classes.active} ${classes.username}`}
      >
        {isEmpty(username) ? "LOGIN" : `HI, ${username.toUpperCase()}`}
      </Typography>

      <Menu
        id="username-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/account");
            handleClose();
          }}
        >
          My account
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/account");
            handleClose();
          }}
        >
          Orders
        </MenuItem>
        <MenuItem
          onClick={() => {
            logout(signOut);
            handleClose();
          }}
        >
          Log out
        </MenuItem>
      </Menu>
    </>
  );
};
export default Username;
